@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');


.homepage {
  text-align: center;
  align-self: center;
}

body {
    margin: 0;
    background-color: black;
    text-align: center;
  }
  html,
  body,
  #root {
    height: 100%;
  }

  .title {
    color: white;
  }

  .titlediv {
    margin-top: 20vh;
    text-align: center;
    font-size: 8vh;
    font-family: 'Montserrat', sans-serif;
  }


  .text-box {
    text-align: center;
    align-self: center;
   margin-top: 20vh;
}

.button-53 {
  background-color: #3DD1E7;
  box-sizing: border-box;
  color: #000000;
  display: flex;
  font-family: ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
  transform: rotate(-2deg);
}

@media (min-width: 768px) {
  .button-53 {
    padding: .75rem 3rem;
    font-size: 1.25rem;
  }
}

.button-70 {
  background-image: linear-gradient(#0dccea, #0d70ea);
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, .3) 0 5px 15px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: Montserrat,sans-serif;
  font-size: .9em;
  margin: 5px;
  padding: 10px 15px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-left: 50%;
}

.splineBody {
  background-color: #9de5ff;
  max-height: fit-content
}